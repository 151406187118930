import React, { Component } from "react";
import abroncs from "./images/anvelopa.jpg";
import "./style/item.css";
import BackDrop from "../other/backdrop";
import Detail from "./itemdetail";

function priceFormating(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " Lej";
}

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailOpen: false,
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (props.shouldOpen) {
      return {
        detailOpen: true,
      };
    }
  }
  handleDetail = () => {
    this.setState({
      detailOpen: true,
    });
  };
  onExit = () => {
    this.props.onExit();
    this.setState({
      detailOpen: false,
    });
  };
  onClickSugestion = (item) => {
    this.setState({
      detailOpen: false,
    });

    this.props.onSugestionClick(item);
  };
  render() {
    let detailView;
    if (this.state.detailOpen) {
      detailView = (
        <div>
          <BackDrop
            onClick={() => {
              console.log("YEEE");
              this.onExit();
            }}
          />
          <Detail
            anvelopa={this.props.anvelopa}
            OtherSugestions={this.props.OtherSugestions}
            onExit={() => this.onExit()}
            handleClick={(item) => this.onClickSugestion(item)}
            marcaFilter={this.props.marcaFilter}
            profilFilter={this.props.profilFilter}
            detailList={this.props.detailList}
            addProdus={(produs) => {
              this.props.addProdus(produs);
            }}
          />
        </div>
      );
    }
    let produsDenumire = "";

    produsDenumire = this.props.anvelopa.Produs;

    return (
      <div>
        <div
          className="product-card"
          onClick={() => {
            this.handleDetail();
          }}
        >
          <div className="product-card-image">
            <img src={abroncs} style={{ width: "100%" }} />
          </div>
          <div className="product-card-content">
            <div className="product-card-title">{produsDenumire}</div>
            <div className="product-card-price">
              {priceFormating(this.props.anvelopa.PretLista)}
            </div>
            <div className="product-card-description">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </div>
          </div>
        </div>
        {detailView}
      </div>
    );
  }
}

export default Item;
