import React, { Component } from "react";
import axios from "axios";
import StandardInput from "../turisme/standardInput";
import DimeniuneInput from "../agro/dimensiune2_0";

import "./style/noresult.css";
class QuestionBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ansList: undefined,
      listShow: false,
    };
  }
  componentDidMount() {
    let str = this.props.url_ans + this.props.quest.id;
    axios.get(str).then((res) => this.setState({ ansList: res.data }));
  }
  get getAns() {
    if (this.state.listShow) {
      if (this.props.quest.QuestionVar === "dimensiune") {
        return (
          <DimeniuneInput
            handleChange={(e, attr) => {
              this.props.handleChangeInstant(e, attr);
            }}
            questionData={this.props.quest}
            tipAnvelopa={this.props.tipAnvelopa}
            tipus={this.props.tipus}
            utilizare={this.props.utilizare}
            url_dimensiune={this.props.url_dimensiune}
          />
        );
      } else
        return (
          <StandardInput
            url_ans={this.props.url_ans}
            questionData={this.props.quest}
            handleChange={(e) => {
              this.props.handleChange(e);
            }}
          />
        );
    }
  }
  render() {
    return (
      <div>
        <div
          className="noresult-title"
          onClick={() => {
            this.setState({ listShow: !this.state.listShow });
          }}
        >
          {this.props.quest.Question}
        </div>
        {this.getAns}
      </div>
    );
  }
}

class NoResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questList: [],
    };
  }
  async componentDidMount() {
    if (this.props.questIdList)
      await this.props.questIdList.map((n) => {
        if (n !== "-1") {
          let str = this.props.url_quest + n;
          console.log(str);
          axios.get(str).then((res) => {
            let l = this.state.questList;
            l.push(res.data[0]);
            console.log(l);
            this.setState({ questList: l });
          });
        }
      });
  }
  get getQuestions() {
    if (this.state.questList[0] !== undefined) {
      return (
        <div>
          {this.state.questList.map((quest) => {
            return (
              <QuestionBox
                quest={quest}
                url_ans={this.props.url_ans}
                tipus={this.props.tipus}
                utilizare={this.props.utilizare}
                url_dimensiune={this.props.url_dimensiune}
                handleChange={(e) => this.props.handleChange(e)}
                handleChangeInstant={(e, attr) => {
                  this.props.handleChangeInstant(e, attr);
                }}
              />
            );
          })}
        </div>
      );
    }
  }
  render() {
    return (
      <div>
        <div className="noresult-sad">No result</div>
        <div className="noresult-desc">lorem ipsum</div>
        {this.getQuestions}
      </div>
    );
  }
}
export default NoResult;
