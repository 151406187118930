import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import "./style/main-menu.css";
import desktopImage from "./images/05_style3.jpg";

function MenuItem(props) {
  return (
    <div className="menu-list-item">
      <div className="img-container">
        <img
          src={props.menu.Image}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div className="title">{props.menu.Title}</div>
      <ul>
        {props.paragraph.map((item) => {
          return <li>{item.ParagraphText}</li>;
        })}
      </ul>
      <div className="spacer" />
      <div className="button">Începe configurarea!</div>
    </div>
  );
}

class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    await axios
      .get("http://127.0.0.1:8000/api/other/menu/")
      .then((res) => this.setState({ menuList: res.data }));
    await axios
      .get("http://127.0.0.1:8000/api/other/paragraph/")
      .then((res) => this.setState({ paragraphList: res.data }));
    this.setState({ ready: true });
  }
  get getMenu() {
    if (this.state.ready) {
      return (
        <div className="menu-list-container">
          {this.state.menuList.map((p) => {
            return (
              <Link to={p.Link} key={p.id}>
                <MenuItem
                  menu={p}
                  paragraph={this.state.paragraphList.filter(
                    (paragraph) => paragraph.Menu === p.id
                  )}
                />
              </Link>
            );
          })}
        </div>
      );
    }
    return;
  }
  render() {
    return (
      <div className="menu-container">
        <div className="spacer" />
        <div className="desktop-image">
          <img src={desktopImage} />
        </div>
        <div className="spacer" />
        {this.getMenu}
      </div>
    );
  }
}
export default MainMenu;
