import React, { Component } from "react";

class Finalizare extends React.Component {
  constructor(props) {
    super(props);
  }
  get getAllert() {
    let alert = false;
    if (this.props.tipus === "turisme") {
      this.props.produseSelectate.map((p) => {
        if (p.cantitate < 4) {
          alert = true;
        }
      });
    }
    if (alert) {
      return;
    } else return;
  }
  render() {
    return (
      <div className="finalizare-container">
        <div></div>
      </div>
    );
  }
}
export default Finalizare;
