import React, { Component } from "react";

import CostSum from "./cosSum";
import Finalizare from "./finalizare";
import "./style/cos.css";
import logo from "./images/logo.png";
import abroncs from "./images/anvelopa.jpg";
import cart from "./images/cart-removebg-preview.png";

function priceFormating(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " Lej";
}

function Alert(props) {
  if (props.tipus === "turisme" && props.produs.cantitate < 4) {
    return (
      <div className="cos-alert">
        Noi recomandăm schimbarea a fiecărui anvelope!
      </div>
    );
  } else return <div />;
}

function ProdusBadge(props) {
  return (
    <div className="cos-badge-container">
      <div className="allert">
        <Alert produs={props.produs} tipus={props.tipus} />
      </div>
      <div className="cos-badge">
        <div className="img">
          <img src={abroncs} style={{ width: "100%" }} />
        </div>
        <div className="description">
          <div className="title">{props.produs.prod.Produs}</div>
          <div className="pret-table">
            <div className="pret-line">
              <div>O bucată:</div>
              <div className="pret">
                {priceFormating(props.produs.prod.PretLista)}
              </div>
            </div>
            <div className="pret-line">
              <div>Total:</div>
              <div className="pret">
                {priceFormating(
                  props.produs.cantitate * props.produs.prod.PretLista
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="cantitate">
          <div>cantitate:</div>
          <div>{props.produs.cantitate}</div>
        </div>
      </div>
      <div className="bottom-line">
        <div
          onClick={() => {
            props.AddCantitate(-1, props.produs.prod.id);
          }}
        >
          -
        </div>
        <div
          onClick={() => {
            props.AddCantitate(1, props.produs.prod.id);
          }}
        >
          +
        </div>
      </div>
    </div>
  );
}

class CosDetail extends React.Component {
  constructor(props) {
    super(props);
  }
  get getBadges() {
    if (this.props.produseSelectate.length > 0) {
      return (
        <div style={{ overflow: "scroll", paddingBottom: "200px" }}>
          {this.props.produseSelectate.map((res) => {
            return (
              <ProdusBadge
                produs={res}
                AddCantitate={(inc, id) => this.props.AddCantitate(inc, id)}
                tipus={this.props.tipus}
              />
            );
          })}
        </div>
      );
    } else
      return (
        <div className="cos-gol">
          <div>Cosul este gol</div>
        </div>
      );
  }
  render() {
    return (
      <div className="cos-detail-container">
        <div className="cos-detail-header">
          <img src={logo} style={{ width: "30%", margin: "0.5rem" }} />
        </div>
        {this.getBadges}
        <div className="bottom-bar2">
          <div>
            <CostSum produseSelectate={this.props.produseSelectate} />
          </div>
          <div className="cos-buttons">
            <div
              onClick={() => {
                this.props.asdf();
              }}
            >
              Înapoi
            </div>
            <div onClick={() => this.props.finalizareClick()}>
              Finalizează comanda
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Cos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finalizare: false,
    };
  }
  finalizareClick = () => {
    this.setState({ finalizare: !this.state.finalizare });
  };
  get getCosDetail() {
    if (this.props.cosOpen) {
      if (!this.state.finalizare) {
        return (
          <CosDetail
            asdf={() => {
              //  console.log("huuu");
              this.props.cosClose();
            }}
            produseSelectate={this.props.produseSelectate}
            AddCantitate={(inc, id) => this.props.AddCantitate(inc, id)}
            finalizareClick={() => this.finalizareClick()}
            tipus={this.props.tipus}
          />
        );
      } else {
        return (
          <Finalizare
            produseSelectate={this.props.produseSelectate}
            tipus={this.props.tipus}
          />
        );
      }
    }
  }
  render() {
    return (
      <div>
        <div className="cos-icon" onClick={this.props.cosClick}>
          <img src={cart} style={{ width: "100%" }} />
        </div>
        {this.getCosDetail}
      </div>
    );
  }
}

export default Cos;
