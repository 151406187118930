import React, { Component } from "react";
import axios from "axios";

import "./style/tractorStandard.css";
import traktor from "./images/remorka.png";

class StandardInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: undefined,
    };
  }
  componentDidMount() {
    axios
      .get(this.props.url_ans + this.props.questionData.id)
      .then((res) => this.setState({ list: res.data }));
  }
  get getAns() {
    if (this.state.list) {
      if (this.state.list[0] && this.state.list[0]["val"] === "numeric") {
        return (
          <div className="agro-numeric-input">
            <input
              type="number"
              onChange={(e) =>
                this.props.handleChange(
                  e.target.value,
                  this.props.questionData.QuestionVar
                )
              }
            />
            {this.getDescriere}
            <span>{this.state.list[0]["text"]}</span>
          </div>
        );
      } else {
        return (
          <div className="option-list">
            {this.state.list.map((item) => {
              return (
                <div key={item.id}>
                  <div
                    className="radiobutton-input"
                    onChange={(e) =>
                      this.props.handleChange(
                        item.val,
                        this.props.questionData.QuestionVar
                      )
                    }
                  >
                    <input
                      type="radio"
                      name="asd"
                      id={item.id}
                      value={item.val}
                    />
                    <label for={item.id}>
                      <span
                        style={{ display: "flex", flexDirection: "flex-start" }}
                      >
                        <div className="radio-button-circle">
                          <div></div>
                        </div>
                        {/*      <div style={{ height: "45px", marginRight: "0.5rem" }}>
                      <img src={traktor} style={{ height: "100%" }} />
                    </div> */}
                        <span>{item.text}</span>
                      </span>
                      <img src={item.Image} style={{ width: "75px" }} />
                    </label>
                    <div className="radio-descriere">{item.descriere}</div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    }
  }
  render() {
    return <div style={{}}>{this.getAns}</div>;
  }
}
export default StandardInput;
