import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import axios from "axios";
import logo from "./logo.svg";
import "./App.css";
import {
  API_CAMION_URL_PAGINA,
  API_CAMION_URL_INTREBARI,
  API_CAMION_URL_RASPUNS,
  API_CAMION_URL_ANVELOPE,
  CAMION_BRAND_URL,
  CAMION_PROFIL_URL,
  API_JANTECAMION_URL_PAGINA,
  API_JANTECAMION_URL_INTREBARI,
  API_JANTECAMION_URL_RASPUNS,
  API_JANTECAMION_URL_JANTE,
  API_AGRO_URL_PAGINA,
  API_AGRO_URL_INTREBARI,
  API_AGRO_URL_RASPUNS,
  API_AGRO_URL_BRAND,
  API_AGRO_URL_PROFIL,
  API_OFFROAD_URL_PAGINA,
  API_OFFROAD_URL_INTREBARI,
  API_OFFROAD_URL_RASPUNS,
  API_OFFROAD_URL_ALERT,
} from "./constants";

import JanteAgroControl from "./components/janteagro/master";
import ToolBar from "./components/other/toolbar";
import ToggleSwitch from "./components/other/toggleswitch";
import SideDrawer from "./components/other/sidedrawer";
import BackDrop from "./components/other/backdrop";
import Results from "./components/results/Results";
import BottomBar from "./components/other/bottombar";
import AgroControl2 from "./components/agro/agrocontrol2_1";
import TurismeControl from "./components/turisme/masterformturisme";
import MainMenu from "./components/menu/mainMenu";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ans: [],
    };
  }

  async getSpeedIndex(speedo) {
    if (speedo === undefined) return undefined;
    var speedIndex = [];
    await axios.get("http://127.0.0.1:8000/api/SpeedView/").then((res) => {
      speedIndex = res.data;
    });
    let i = 0;
    while (speedo > speedIndex[i]["MaxSpeed"]) {
      i++;
    }
    this.setState({
      speedIndex: speedIndex[i]["SpeedIndex"],
    });
  }

  checkPageLength = () => {
    if (this.state.ans[1] === "1") {
      return this.state.noOfPages + 1;
    }
    return this.state.noOfPages;
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  backDropClick = () => {
    this.setState({ sideDrawerOpen: false });
  };

  handlePrev = () => {
    this.setState((prevState) => {
      return { currentStep: prevState.currentStep - 1, resultShow: false };
    });
  };
  handleNext = () => {
    this.setState((prevState) => {
      return { currentStep: prevState.currentStep + 1 };
    });
  };

  handleSubmit = () => {
    this.getSpeedIndex(this.state.ans[6]);
    this.setState({
      resultShow: true,
    });
  };
  onChange = (a) => {
    this.setState({ ans: a });
  };

  onLoadIndex = (front, back) => {
    this.setState({
      frontLoad: front,
      backLoad: back,
    });
  };

  render() {
    let backDrop, results;
    if (this.state.sideDrawerOpen) {
      backDrop = <BackDrop click={this.backDropClick} />;
    }

    if (this.state.resultShow) {
      results = (
        <div>
          <Results
            ans={this.state.ans}
            speedIndex={this.state.speedIndex}
            frontLoad={this.state.frontLoad}
            backLoad={this.state.backLoad}
          />
        </div>
      );
    }

    return (
      <div className="app-container">
        <Router>
          <ToolBar click={this.drawerToggleClickHandler} />
          <SideDrawer visible={this.state.sideDrawerOpen} />
          {backDrop}
          <Switch>
            <Route path="/turisme">
              <TurismeControl
                tipus={"turisme"}
                url_quest={"http://127.0.0.1:8000/api/turisme/quest/?id="}
                url_ans={"http://127.0.0.1:8000/api/turisme/ans/?question="}
                url_filter={
                  "http://127.0.0.1:8000/api/anvelope/anvelope/?Categorie=ANV.TUR%26UTIL"
                }
                first_pageID={"1"}
                url_dimensiune={
                  "http://127.0.0.1:8000/api/anvelope/marimecat/distinct_size/?LatimeDenumire=&InaltimeDenumire=&Raza=&Categorie=ANV.TUR%26UTIL"
                }
              />
            </Route>

            <Route path="/agro">
              <AgroControl2
                tipus={"agro"}
                url_quest={"http://127.0.0.1:8000/api/agro/quest/?id="}
                url_ans={"http://127.0.0.1:8000/api/agro/ans/?question="}
                url_extraquest={
                  "http://127.0.0.1:8000/api/agro/extraquest/?question="
                }
                url_filter={
                  "http://127.0.0.1:8000/api/anvelope/anvelope/?Categorie=AGROINDUSTRIAL"
                }
                url_dimensiune={
                  "http://127.0.0.1:8000/api/anvelope/marime/distinct_size/?LatimeDenumire=&InaltimeDenumire=&Janta=&mrFilter="
                }
                first_pageID={"10"}
              />
            </Route>

            <Route path="/camion">
              <TurismeControl
                tipus={"camion"}
                url_quest={"http://127.0.0.1:8000/api/camion/quest/?id="}
                url_ans={"http://127.0.0.1:8000/api/camion/ans/?question="}
                url_extraquest={
                  "http://127.0.0.1:8000/api/camion/extraquest/?question="
                }
                url_filter={
                  "http://127.0.0.1:8000/api/anvelope/anvelope/?Categorie=ANV.CAMION+17.5-24.5"
                }
                url_dimensiune={
                  "http://127.0.0.1:8000/api/anvelope/marimecat/distinct_size/?LatimeDenumire=&InaltimeDenumire=&Raza=&Categorie=ANV.CAMION+17.5-24.5"
                }
                url_profil={"http://127.0.0.1:8000/api/camion/profil/?id="}
                url_brand={"http://127.0.0.1:8000/api/camion/brand/?id="}
                first_pageID={"4"}
              />
            </Route>
            <Route path="/jantecamion">
              <AgroControl2
                tipus={"jante"}
                url_quest={"http://127.0.0.1:8000/api/jantecamion/quest/?id="}
                url_ans={"http://127.0.0.1:8000/api/jantecamion/ans/?question="}
                url_filter={"http://127.0.0.1:8000/api/jantecamion/jante/"}
                url_dimensiune={
                  "http://127.0.0.1:8000/api/anvelope/marimecat/distinct_size/?LatimeDenumire=&InaltimeDenumire=&Raza=&Categorie=ANV.CAMION+17.5-24.5"
                }
                first_pageID={"4"}
              />
            </Route>
            <Route path="/offroad">
              <TurismeControl
                tipus={"offroad"}
                url_quest={"http://127.0.0.1:8000/api/offroad/quest/?id="}
                url_ans={"http://127.0.0.1:8000/api/offroad/ans/?question="}
                url_filter={"http://127.0.0.1:8000/api/anvelope/anvelope/"}
                url_dimensiune={
                  "http://127.0.0.1:8000/api/anvelope/marimecat/distinct_size/?LatimeDenumire=&InaltimeDenumire=&Raza=&Categorie="
                }
                first_pageID={"5"}
              />
            </Route>
            <Route path="/janteagro">
              <JanteAgroControl />
            </Route>
            <Route path="/">
              <MainMenu />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
