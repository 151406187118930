import React, { Component } from "react";

import Formular from "./formular";
import AgroControl2 from "../agro/agrocontrol2_1";
import {
  API_JANTECAMION_URL_PAGINA,
  API_JANTECAMION_URL_INTREBARI,
  API_JANTECAMION_URL_RASPUNS,
  API_JANTECAMION_URL_JANTE,
  CAMION_BRAND_URL,
  CAMION_PROFIL_URL,
} from "./constants";
import "./style/janteagro.css";
function Menu(props) {
  return (
    <div className="menu">
      <ul className="menu-list">
        <div onClick={() => props.onTractor()}>
          <li>Jante Tractor</li>
        </div>
        <div onClick={() => props.onAlte()}>
          <li>Alte</li>
        </div>
      </ul>
    </div>
  );
}

class JanteAgroControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formularTractor: false,
      formularAlte: false,
    };
  }
  get Page() {
    if (this.state.formularTractor) {
      return (
        <div>
          <Formular />
        </div>
      );
    } else if (this.state.formularAlte) {
      return (
        <div>
          <AgroControl2
            url_quest={"http://127.0.0.1:8000/api/jantecamion/quest/?id="}
            url_ans={"http://127.0.0.1:8000/api/jantecamion/ans/?question="}
            url_filter={"http://127.0.0.1:8000/api/agro/anvelope/"}
            first_pageID={"4"}
          />
        </div>
      );
    } else {
      return (
        <div style={{ paddingTop: "63px" }}>
          <Menu
            onTractor={() =>
              this.setState({
                formularTractor: true,
              })
            }
            onAlte={() => this.setState({ formularAlte: true })}
          />
        </div>
      );
    }
  }
  render() {
    return <div>{this.Page}</div>;
  }
}
export default JanteAgroControl;
