import React, { Component } from "react";
import axios from "axios";

import "./style/dimensiune2.css";

const InvisibleBackDrop = (props) => (
  <div className="backdrop-inv" onClick={props.click} />
);

class DimeniuneInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      dimList: undefined,
      dim: undefined,
    };
  }
  async componentDidMount() {
    let str = this.props.url_dimensiune;
    if (this.props.tipus === "agro") {
      let utilaj;
      await axios
        .get(
          "http://127.0.0.1:8000/api/agro/utilaj/?Utilizare=&id=" +
            this.props.utilajID
        )
        .then((res) => (utilaj = res.data[0]));
      console.log(utilaj);
      utilaj.GrupaB.map((item) => {
        str += item;
        str += "%7C";
      });
    } else if (this.props.tipus === "offroad") {
      str += this.props.utilizare;
      console.log(this.props.utilizare);
    }

    if (str) {
      await axios.get(str).then((res) => this.setState({ dimList: res.data }));
      console.log("lololo", str);
    }
  }
  get getInput() {
    if (this.state.dimList !== undefined && this.state.showDropdown) {
      let list = this.state.dimList.filter(
        (item) =>
          item.Dimensiune !== null &&
          (item.Dimensiune.includes(this.state.dim) ||
            this.state.dim === undefined)
      );
      if (this.props.tipAnvelopa === "sor") {
        list = list.filter(
          (item) =>
            item.Dimensiune.includes("/90R") || item.Dimensiune.includes("/95R")
        );
      }
      return (
        <ul>
          {list.map((item, index) => {
            return (
              <li
                onClick={() => {
                  this.setState({ dim: item.Dimensiune });
                  this.props.handleChange(
                    item.Dimensiune,
                    this.props.questionData.QuestionVar
                  );
                  if (item.DimensiuneEchivalenta)
                    this.props.handleChange(
                      item.DimensiuneEchivalenta.replace(".", ","),
                      this.props.questionData.QuestionVar + "Echiv"
                    );
                }}
                key={index}
              >
                {item.Dimensiune}
              </li>
            );
          })}
        </ul>
      );
    }
  }
  render() {
    let back = null;
    if (this.state.showDropdown) {
      back = (
        <InvisibleBackDrop
          click={() => {
            this.setState({ showDropdown: false });
            this.props.handleChange(
              this.state.dim,
              this.props.questionData.QuestionVar
            );
          }}
        />
      );
    } else {
      back = null;
    }
    return (
      <div
        className="dimensiune-input"
        onFocus={() => this.setState({ showDropdown: true })}
      >
        <input
          type="text"
          value={this.state.dim}
          placeholder="255/65R17"
          onChange={(e) => {
            this.setState({ dim: e.target.value });
          }}
        />
        {this.getInput}
        {back}
      </div>
    );
  }
}

export default DimeniuneInput;
