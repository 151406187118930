import React, { Component } from "react";
import axios from "axios";

import IndustrieList from "./industrieList";
import UtilajList from "./utilajList";
import StandardInput from "./standardInput";
import DimeniuneInput from "./dimensiune2_0";
import ExtraQuestion from "./extraQuestion";
import Results from "../results/Results";
import ResultControl from "../results/resultControl";

import "./style/page.css";

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extraQuestion: undefined,
    };
  }
  componentDidMount() {
    axios
      .get(this.props.url_extraquest + this.props.questionData.id)
      .then((res) => this.setState({ extraQuestion: res.data }));
  }
  get getExtraQuestion() {
    if (this.state.extraQuestion) {
      return this.state.extraQuestion.map((question) => {
        return (
          <ExtraQuestion
            question={question}
            handleChange={(e, attr) => {
              this.props.handleChange(e, attr);
            }}
          />
        );
      });
    }
  }

  get getAnswer() {
    if (this.props.questionData.QuestionVar === "industrie") {
      return (
        <IndustrieList
          handleChange={(e) => {
            this.props.handleChange(e, "utilizareID");
          }}
        />
      );
    } else if (this.props.questionData.QuestionVar === "utilaj") {
      return (
        <UtilajList
          utilizareID={this.props.utilizareID}
          handleChange={(e, attr) => {
            this.props.handleChange(e, attr);
          }}
        />
      );
    } else if (this.props.questionData.QuestionVar.includes("dimensiune")) {
      return (
        <DimeniuneInput
          utilajID={this.props.utilajID}
          handleChange={(e, attr) => {
            this.props.handleChange(e, attr);
          }}
          questionData={this.props.questionData}
          tipAnvelopa={this.props.tipAnvelopa}
          tipus={this.props.tipus}
          url_dimensiune={this.props.url_dimensiune}
        />
      );
    } else {
      return (
        <StandardInput
          url_ans={this.props.url_ans}
          questionData={this.props.questionData}
          handleChange={(e, attr) => {
            this.props.handleChange(e, attr);
          }}
        />
      );
    }
  }
  get getVideo() {
    if (this.props.questionData.VideoUrl) {
      return (
        <div>
          <iframe
            width="100%"
            height="auto"
            src={this.props.questionData.VideoUrl}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      );
    }
  }
  get getData() {
    if (this.props.questionData) {
      let image = null;
      if (this.props.questionData.Image !== null) {
        image = (
          <img src={this.props.questionData.Image} style={{ width: "100%" }} />
        );
      }
      console.log(this.props.questionData.Question);
      return (
        <div className="page">
          {this.getVideo}
          <div className="image">{image}</div>
          <div className="title">{this.props.questionData.Question}</div>
          <div className="descriere">
            {this.props.questionData.QuestionDescriere}
          </div>
          {this.getAnswer}
        </div>
      );
    }
  }
  async handleSkip() {
    await this.props.handleChange(
      "Nu stiu",
      this.props.questionData.QuestionVar
    );
    this.props.handleNext();
  }
  get getSkip() {
    if (this.props.questionData.nuStiu) {
      return (
        <div
          className="skip-button"
          onClick={() => {
            this.handleSkip();
          }}
        >
          Nu știu
        </div>
      );
    }
  }
  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {this.getData}
        {this.getExtraQuestion}
        {this.getSkip}
      </div>
    );
  }
}

class AgroControl2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionData: undefined,
      selectedAns: undefined,
      questIdList: [Number(this.props.first_pageID)],
    };
  }

  componentDidMount() {
    axios
      .get(this.props.url_quest + this.props.first_pageID)
      .then((res) => this.setState({ questionData: res.data[0] }));
  }

  async handleNext() {
    let ok = false;
    let i = 0;
    let nextPage = null;
    while (!ok) {
      let cond = this.state.questionData.NextQuestionCondition[i];
      let condBoolean = true;
      cond.condition.map((c) => {
        if (c.question === "default") {
          ok = true;
          nextPage = cond.nextPageId;
        } else if (this.state[c.question] === c.answer) {
          ok = true;
          nextPage = cond.nextPageId;
        } else {
          condBoolean = false;
        }
      });
      if (!condBoolean) {
        ok = false;
      }
      if (nextPage === "-1") {
        await this.defFilterUrl();
        this.setState({ resultShow: true });
      }
      i++;
    }
    let temp = this.state.questIdList;
    temp.push(nextPage);
    this.setState({ questIdList: temp });
    axios
      .get(this.props.url_quest + nextPage)
      .then((res) => this.setState({ questionData: res.data[0] }));
    axios
      .get(this.props.url_quest + nextPage)
      .then((res) => this.setState({ questionData: res.data[0] }));
  }

  async defineGroup() {
    let utilaj;
    await axios
      .get(
        "http://127.0.0.1:8000/api/agro/utilaj/?Utilizare=&id=" +
          this.state.utilajID
      )
      .then((res) => (utilaj = res.data[0]));
    let grstr = "";
    //OTR+RADIAL%7COTR+DIAGONA
    utilaj.GrupaB.map((item) => {
      grstr += item;
      grstr += "%7C";
    });
    return grstr;
  }

  async calculateLoadIndex(tag) {
    /// LOADTABLE -> LOADINDEX TABLA
    let load;
    let loadTable;
    await axios.get("http://127.0.0.1:8000/api/LoadView/").then((res) => {
      loadTable = res.data;
    });

    ///TRAKTOR
    if (this.state.utilajID === "54") {
      //  load = await calcTractorLoad();
      if (this.state.masaTotala) {
        let ratio;
        if (this.state.tipTractor === "4WD") {
          ratio = 0.4;
        } else {
          ratio = 0.6;
        }
        if (tag == "Fata") {
          ratio = 1 - ratio;
        }
        let gr = Number(this.state.masaTotala) * ratio;
        if (
          this.state["greutateImplement" + tag] &&
          this.state["distImplement" + tag]
        ) {
          gr +=
            (Number(this.state["distImplement" + tag]) *
              Number(this.state["greutateImplement" + tag])) /
            2;
        }
        if (this.state.wheelbase && this.state.wheelbase !== "Nu stiu") {
          gr = gr / Number(this.state.wheelbase);
        } else {
          gr = gr / 2;
        }
        let i = 0;
        while (gr < loadTable[i]["Mass"]) {
          i++;
        }
        i--;
        return loadTable[i]["LoadIndex"];
      } else {
        ///greutateAxaFata
        let gr = "greutateAxa" + tag;
        if (this.state[gr] === undefined) return undefined;
        gr = Number(this.state[gr]);
        if (
          this.state["greutateImplement" + tag] &&
          this.state["distImplement" + tag]
        ) {
          gr +=
            Number(this.state["distImplement" + tag]) *
            Number(this.state["greutateImplement" + tag]);
        }
        if (this.state.wheelbase && this.state.wheelbase !== "Nu stiu") {
          gr = gr / Number(this.state.wheelbase);
        } else {
          gr = gr / 2;
        }
        let i = 0;
        console.log(loadTable);
        while (gr < loadTable[i]["Mass"]) {
          i++;
        }
        return loadTable[i]["LoadIndex"];
      }
    } else if (this.state.utilajID === "57") {
      ///REMORKA
      if (this.state.masaTotala === undefined) return undefined;
      let gr = Number(this.state.masaTotala) * 1.2;
      gr = gr / (Number(this.state.nrAnv) * Number(this.state.nrAxe));
      let i = 0;
      while (gr < loadTable[i]["Mass"]) {
        i++;
      }
      return loadTable[i]["LoadIndex"];
    } else if (this.state.utilajID === "56") {
      ///KOMBAJN
      let ratio = 0.7;
      if (this.state.axa === "spate") {
        ratio = 1 - ratio;
      }
      let gr = (this.state.masaTotala * ratio) / 2;
      let i = 0;
      while (gr < loadTable[i]["Mass"]) {
        i++;
      }
      return loadTable[i]["LoadIndex"];
    } else if (this.state.utilajID === "55") {
      ///TRACTABILE
      let gr = this.state.masaTotala / Number(this.state.nrAnv);
      let i = 0;
      while (gr < loadTable[i]["Mass"]) {
        i++;
      }
      return loadTable[i]["LoadIndex"];
    }
    return load;
  }

  ///Filter Url def
  async defFilterUrl() {
    //?LatimeDenumire=&InaltimeDenumire=&Janta=&SI=&grFilter=&liFilter=
    let filterUrlList = [];
    ///Filter Url Agro
    if (this.props.tipus === "agro") {
      const keys = Object.entries(this.state);
      for (const k in keys) {
        let tag = "";
        if (keys[k][0].includes("dimensiune") && keys[k][1]) {
          let eticheta = "";
          if (keys[k][0].includes("Fata")) {
            eticheta += "FAȚĂ ";
            tag = "Fata";
          } else {
            eticheta += "SPATE ";
            tag = "Spate";
          }
          let str = this.props.url_filter;
          let dim = keys[k][1];
          dim = dim.replace("(", "");
          dim = dim.replace(")", "");
          if (dim.includes("/")) {
            eticheta += "RADIAL";
            let dimensiuneCut = dim.split("/");
            str += "&LatimeDenumire=";
            str += dimensiuneCut[0];
            dimensiuneCut = dimensiuneCut[1].split("R");
            str += "&InaltimeDenumire=";
            str += dimensiuneCut[0];
            str += "&Raza=";
            str += dimensiuneCut[1];
          } else {
            dim = dim.replace(",", "%2C");
            eticheta += "DIAGONAL";
            let dimensiuneCut = dim.split("R");
            //str = str.replace('s','S');
            str += "&LatimeDenumire=";
            str += dimensiuneCut[0];
            str += "&Raza=";
            str += dimensiuneCut[1];
          }

          if (this.state.vitMax) {
            str += "&siFilter=";
            str += this.state.vitMax;
          }
          str += "&grFilter=";
          let grstr = await this.defineGroup();
          str += grstr;
          if (this.state.agriflex) {
            str += "&agriflex=";
            str += this.state.agriflex;
          }
          str += "&liFilter=";
          let listr = await this.calculateLoadIndex(tag);
          if (listr) str += listr;
          if (this.state.sb) {
            str += "&TipJanta=";
            str += this.state.sb;
          }
          if (this.state.sg) {
            str += "&SufixDenumire=";
            str += this.state.sg;
          }
          let filterBlock = [eticheta, str];
          filterUrlList.push(filterBlock);
        }
      }
    } else if (this.props.tipus.includes("jante")) {
      let str = this.props.url_filter;
      //http://127.0.0.1:8000/api/jantecamion/jante/?Prezoane=&DiametruGauraButuc=&Produs=&DimeniuniAnvelopeCompatibile=265%2F70R17.5
      str += "?Produs=";
      if (this.state.JantaDim) {
        str += this.state.JantaDim;
      }
      if (this.state.dimensiune) {
        str += "&DimeniuniAnvelopeCompatibile=";
        let l = this.state.dimensiune.replace(",", ".");

        str += l;
      }
      if (this.state.nrPrez) {
        str += "&Prezoane=";
        str += this.state.nrPrez;
      }
      if (this.state.diamGaurCent) {
        str += "&DiametruGauraButuc=";
        str += this.state.diamGaurCent;
        str += " mm";
      }
      let temp = ["asd", str];
      filterUrlList.push(temp);
    }
    this.setState({ filterUrlList: filterUrlList });
  }

  handleChange = (e) => {
    console.log(e);
    e.map((item) => {
      if (item.ans) {
        this.setState({ [item.question]: item.ans });
      } else {
        console.log(this.state[item.question]);
        if (!this.state[item.question]) {
          this.setState({ [item.question]: 0 });
        }

        let n = this.state[item.question] + Number(item.numericans);
        console.log(n);
        this.setState({ [item.question]: n });
      }
    });
  };

  get getButtons() {
    if (!this.state.resultShow) {
      return (
        <div className="button-bar">
          <div onClick={() => this.handlePrev()} className="prev-question">
            Întrebarea anterioară
          </div>
          <div onClick={() => this.handleNext()} className="next-question">
            Întrebarea următoare
          </div>
        </div>
      );
    }
  }

  async handlePrev() {
    if (this.state.questIdList.length > 1) {
      this.setState({ prepChanges: null });
      console.log(this.state.questIdList[this.state.questIdList.length - 2]);
      let nextPage = this.state.questIdList[this.state.questIdList.length - 2];
      axios
        .get(this.props.url_quest + nextPage)
        .then((res) => this.setState({ questionData: res.data[0] }));
      let temp = this.state.questIdList;
      temp.pop();
      this.setState({ questIdList: temp });
    }
  }

  get getPage() {
    if (this.state.resultShow === true) {
      return (
        <ResultControl
          filterUrlList={this.state.filterUrlList}
          marcaFilter={this.props.url_brand}
          profilFilter={this.props.url_profil}
          axa={this.state.axa}
          tipus={this.props.tipus}
        />
      );
    }
    if (this.state.questionData) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Page
            questionData={this.state.questionData}
            url_extraquest={this.props.url_extraquest}
            url_ans={this.props.url_ans}
            url_dimensiune={this.props.url_dimensiune}
            handleChange={(e, attr) => this.setState({ [attr]: e })}
            utilizareID={this.state.utilizareID}
            utilajID={this.state.utilajID}
            key={this.state.questionData.id}
            handleNext={() => this.handleNext()}
            resultShow={this.state.resultShow}
            tipAnvelopa={this.state.tipAnvelopa}
            tipus={this.props.tipus}
          />
          {this.getButtons}
        </div>
      );
    }
  }

  render() {
    return <div style={{ backgroundColor: "#f8f8fb" }}>{this.getPage}</div>;
  }
}

export default AgroControl2;
