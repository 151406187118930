import React, { Component } from "react";
import axios from "axios";

function Box(props) {
  return (
    <div
      className="radiobutton-input"
      onChange={() => {
        props.handleChange(props.item.val);
      }}
    >
      <input
        type="radio"
        name="asd"
        id={props.item.id}
        value={props.item.val}
      />
      <label for={props.item.id}>
        <span style={{ display: "flex", flexDirection: "flex-start" }}>
          <div className="radio-button-circle">
            <div></div>
          </div>
          <span>{props.item.text}</span>
        </span>
      </label>
    </div>
  );
}

class StandardInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    let str = this.props.url_ans + this.props.questionData.id;
    axios.get(str).then((res) => this.setState({ ans: res.data }));
  }
  get getAns() {
    if (this.state.ans) {
      return (
        <div className="option-list">
          {this.state.ans.map((item) => {
            return (
              <div>
                <Box
                  item={item}
                  handleChange={(e) => this.props.handleChange(e)}
                />
              </div>
            );
          })}
        </div>
      );
    }
  }
  render() {
    return <div>{this.getAns}</div>;
  }
}

export default StandardInput;
