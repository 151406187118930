import React, { Component } from "react";
import Item from "./item";
import axios from "axios";
import NoResult from "./noresult";
import detailList from "././resultsshouldshow.json";

import "./style/resultContainer.css";
async function loadData(str) {
  let list = [];
  await axios.get(str).then((res) => {
    list = res.data;
  });

  return list;
}

class Results extends React.Component {
  state = {
    List: [],
    filterUrl: "",
    listIsReady: false,
    detailID: undefined,
    detailList: undefined,
  };

  constructor(props) {
    super(props);
    this.setState({
      filterUrl: props.filterUrl,
    });
  }

  static getDerivedStateFromProps(props, state) {
    //console.log(props.filterUrl);
    //axios.get(props.filterUrl).then((res) => {
    ////    console.log(res.data);
    //    return {
    //      List: res.data,
    //      listIsReady: true,
    //    };
    //    });
    //  console.log(props.filterUrl);
    return { filterUrl: props.filterUrl };
  }

  componentDidMount() {
    axios.get(this.state.filterUrl).then((res) => {
      console.log(res.data);
      this.setState({
        List: res.data,
        listIsReady: true,
      });
    });
    this.setState({
      detailList: detailList,
    });
  }
  componentDidUpdate(prevState, state) {
    if (prevState.filterUrl !== this.state.filterUrl) {
      axios.get(this.state.filterUrl).then((res) => {
        console.log(res.data);
        this.setState({
          List: res.data,
          listIsReady: true,
        });
      });
    }
  }
  onSugestionClick = (a) => {
    this.setState({
      detailID: a.DenumireArticol,
    });
  };
  onExit = () => {
    this.setState({
      detailID: undefined,
    });
  };
  render() {
    let items;

    if (this.state.listIsReady) {
      if (this.state.List[0] === undefined) {
        return (
          <NoResult
            url_ans={this.props.url_ans}
            url_quest={this.props.url_quest}
            questIdList={this.props.questIdList}
            tipus={this.props.tipus}
            utilizare={this.props.utilizare}
            url_dimensiune={this.props.url_dimensiune}
            handleChange={this.props.handleChange}
            handleChangeInstant={(e, attr) => {
              this.props.handleChangeInstant(e, attr);
            }}
          />
        );
      }
      items = this.state.List.map((res, index) => {
        let itemList = this.state.List;
        //itemList.splice(index, 1);
        let open = false;
        console.log(res.DenumireArticol, this.state.detailID);
        if (res.Produs)
          if (res.Produs === this.state.detailID) {
            open = true;
          }
        return (
          <Item
            anvelopa={res}
            OtherSugestions={itemList}
            onSugestionClick={(a) => this.onSugestionClick(a)}
            shouldOpen={open}
            onExit={this.onExit}
            marcaFilter={this.props.marcaFilter}
            profilFilter={this.props.profilFilter}
            detailList={this.state.detailList}
            addProdus={(produs) => {
              this.props.addProdus(produs);
            }}
            key={index}
          />
        );
      });
    } else {
      items = null;
    }
    let detailView = null;

    return (
      <div
        className="results-list-container"
        style={{ backgroundColor: "#f6f6f6" }}
      >
        <div>{items}</div>
      </div>
    );
  }
}

export default Results;
