import React, { Component } from "react";

import "./style/sum.css";
function priceFormating(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " Lej";
}
function CostSum(props) {
  let t = 0;
  if (props.produseSelectate)
    props.produseSelectate.map((l) => {
      t = t + l.cantitate * l.prod.PretLista;
    });
  return <div className="cos-sum">{priceFormating(t)}</div>;
}

export default CostSum;
