import React, { Component } from "react";
import axios from "axios";

function Box(props) {
  return (
    <div
      className="radiobutton-input"
      onChange={(e) => {
        props.handleChange(
          e.target.checked,
          props.item.val[0]["ans"],
          props.item.val[0]["question"]
        );
      }}
    >
      <input
        type="checkbox"
        name="asd"
        id={props.item.id}
        value={props.item.id}
      />
      <label for={props.item.id}>
        <span style={{ display: "flex", flexDirection: "flex-start" }}>
          <div className="radio-button-square">
            <div></div>
          </div>
          <span>{props.item.text}</span>
        </span>
      </label>
    </div>
  );
}

class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { res: [] };
  }
  componentDidMount() {
    let str = this.props.url_ans + this.props.questionData.id;
    axios.get(str).then((res) => this.setState({ ans: res.data }));
  }
  handleChange(check, val, question) {
    console.log(question);
    if (check) {
      let t = this.state.res;
      t.push(val);
      this.setState({ res: t });
      let a = [{ ans: t, question: question }];
      this.props.handleChange(a);
    } else {
      let t = this.state.res;
      let idx = t.indexOf(val);
      t.splice(idx, 1);
      this.setState({ res: t });
      let a = [{ ans: t, question: question }];
      this.props.handleChange(a);
    }
  }

  get getAnsList() {
    if (this.state.ans)
      return (
        <div className="option-list">
          {this.state.ans.map((a) => {
            return (
              <div>
                <Box
                  item={a}
                  handleChange={(check, val, question) => {
                    this.handleChange(check, val, question);
                  }}
                />
              </div>
            );
          })}
        </div>
      );
  }
  render() {
    return <div>{this.getAnsList}</div>;
  }
}
export default CheckBox;
