import React from "react";
import { Link } from "react-router-dom";

import logo from "./images/logo.png";
import auto from "./images/Kocsi.png";
import agroKerek from "./images/Agro kerek.png";
import kamionKerek from "./images/kamion kerek.png";
import kamion from "./images/Kamion.png";
import offroad from "./images/Offroad.png";
import agro from "./images/Traktor.png";
import "./style/sidedrawer.css";

function refreshPage() {
  window.location.reload(false);
}
const SideDrawer = (props) => {
  let sideDrawerClass = "side-drawer";
  if (props.visible) {
    sideDrawerClass = "side-drawer open";
  }

  return (
    <nav className={sideDrawerClass}>
      <div className="logo">
        <img src={logo} />
      </div>
      <ul onClick={refreshPage}>
        <Link to="/turisme">
          <li>
            <div className="side-icon">
              <img src={auto} />
            </div>
            Turisme
          </li>
        </Link>
        <Link to="/agro">
          <li>
            <div className="side-icon">
              <img src={agro} />
            </div>
            Agroindustrial
          </li>
        </Link>
        <Link to="/camion">
          <li>
            <div className="side-icon">
              <img src={kamion} />
            </div>
            Camioane
          </li>
        </Link>
        <Link to="/offroad">
          <li>
            <div className="side-icon">
              <img src={offroad} />
            </div>
            Off Road
          </li>
        </Link>
        <Link to="/jantecamion">
          <li>
            <div className="side-icon2">
              <img src={kamionKerek} />
            </div>
            Jante Camion
          </li>
        </Link>
        <Link to="/janteagro">
          <li>
            <div className="side-icon2">
              <img src={agroKerek} />
            </div>
            Jante Agro
          </li>
        </Link>
      </ul>
    </nav>
  );
};
export default SideDrawer;
