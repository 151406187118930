import React, { Component } from "react";

import "./style/toggle.css";

class ToggleSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      on: false,
    };
  }

  click = () => {
    this.props.extraFunction(this.props.prevState);
    this.setState((prevState) => {
      return { on: !prevState.on };
    });
  };

  render() {
    let left = "";
    let right = "";
    if (this.state.on) {
      right = "toggle-switch-active";
      left = "toggle-switch-inactive";
    } else {
      left = "toggle-switch-active";
      right = "toggle-switch-inactive";
    }
    return (
      <div className="toggle-switch" onClick={this.click}>
        <div className={left}>{this.props.OnVal}</div>
        <div className={right}>{this.props.OfVal}</div>
      </div>
    );
  }
}

export default ToggleSwitch;
