import React, { Component } from "react";
import axios from "axios";

import Results from "./Results";
import "./style/resultControl.css";
import ExtraFilterControl from "./extraFilter";
import Cos from "./cos";

class Tab extends React.Component {
  constructor(props) {
    super(props);
  }
  get getResults() {
    if (this.props.active)
      return (
        <Results
          filterUrl={this.props.filterUrl}
          marcaFilter={this.props.marcaFilter}
          profilFilter={this.props.profilFilter}
          addProdus={(produs) => this.props.addProdus(produs)}
          key="0"
        />
      );
  }

  render() {
    let style = "tab";
    if (this.props.active) style += " tab-active";
    return (
      <div>
        <div
          className={style}
          onClick={() => this.props.handleClick(this.props.id)}
        >
          {this.props.title}
        </div>
        {this.getResults}
      </div>
    );
  }
}

class ResultControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fata: true,
      active: [],
      cosOpen: false,
      produseSelectate: [],
    };
  }

  addProdus = (produs) => {
    /*
    Adauga un produs la lista produselor care clientul doreste sa cumpere
    produseSelectate -> un array de obiecte, un obiect are doua argumente
    detali despre produs si cantitatea
    */
    let contains = false;
    let list = this.state.produseSelectate;
    list.map((pr) => {
      if (pr.prod.id === produs.id) {
        pr.cantitate++;
        contains = true;
      }
    });
    if (!contains) {
      list.push({ prod: produs, cantitate: 1 });
    }
    this.setState({ produseSelectate: list });
  };

  componentDidMount() {
    let a = [];
    this.props.filterUrlList.map((asd) => a.push(false));
    a[0] = true;
    this.setState({ active: a });
  }

  handleClick = (id) => {
    let a = this.state.active;
    a.map((p, i) => {
      if (i === id) {
        console.log(i, id);
        a[i] = true;
      } else a[i] = false;
    });
    console.log(a);
    this.setState({ active: a });
  };
  cosClick = () => {
    this.setState({ cosOpen: !this.state.cosOpen });
  };
  cosClose = () => {
    this.setState({ cosOpen: false });
  };
  get getTabs() {
    //daca avem un singur filtru nu are rost sa folosim tab-urile
    if (this.props.filterUrlList.length > 1) {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {this.props.filterUrlList.map((list, id) => {
            return (
              <Tab
                title={list[0]}
                id={id}
                active={this.state.active[id]}
                filterUrl={list[1]}
                handleClick={(id) => this.handleClick(id)}
                addProdus={(produs) => this.addProdus(produs)}
                key={id}
              />
            );
          })}
        </div>
      );
    } else
      return (
        <Results
          filterUrl={this.props.filterUrlList[0][1]}
          marcaFilter={this.props.marcaFilter}
          profilFilter={this.props.profilFilter}
          url_ans={this.props.url_ans}
          url_quest={this.props.url_quest}
          url_dimensiune={this.props.url_dimensiune}
          questIdList={this.props.questIdList}
          tipus={this.props.tipus}
          utilizare={this.props.utilizare}
          handleChange={this.props.handleChange}
          handleChangeInstant={(e, attr) => {
            this.props.handleChangeInstant(e, attr);
          }}
          addProdus={(produs) => this.addProdus(produs)}
        />
      );
  }
  AddCantitate = (inc, id) => {
    console.log(inc, id);
    let del = false,
      idToDelete = undefined;
    let list = this.state.produseSelectate;
    list.map((l, index) => {
      if (l.prod.id === id) {
        l.cantitate += inc;
        console.log(index);
        if (l.cantitate <= 0) {
          del = true;
          idToDelete = index;
        }
      }
    });
    if (del === true) {
      console.log(list.splice(idToDelete, 1));
    }
    this.setState({ produseSelectate: list });
  };
  render() {
    return (
      <div className="result-control">
        {this.getTabs}
        <ExtraFilterControl />
        <Cos
          cosClick={() => this.cosClick()}
          cosClose={() => {
            this.setState({ cosOpen: false });
          }}
          cosOpen={this.state.cosOpen}
          produseSelectate={this.state.produseSelectate}
          AddCantitate={(inc, id) => this.AddCantitate(inc, id)}
          tipus={this.props.tipus}
        />
      </div>
    );
  }
}

export default ResultControl;
