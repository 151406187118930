import React, { Component } from "react";
import axios from "axios";

import StandardInput from "./standardInput";
import DimeniuneInput from "../agro/dimensiune2_0";
import ResultControl from "../results/resultControl";
import CheckBox from "./checkBoxInput";

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  get getVideo() {
    if (this.props.questionData.VideoUrl) {
      return (
        <div>
          <iframe
            width="100%"
            height="auto"
            src={this.props.questionData.VideoUrl}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      );
    }
  }
  handleChange = (e) => {
    this.setState({ prepChanges: e });
  };
  get getAnswer() {
    if (this.props.questionData.QuestionVar === "dimensiune") {
      return (
        <DimeniuneInput
          handleChange={(e, attr) => {
            this.props.handleChangeInstant(e, "dimensiune");
          }}
          questionData={this.props.questionData}
          tipAnvelopa={this.props.tipAnvelopa}
          tipus={this.props.tipus}
          utilizare={this.props.utilizare}
          url_dimensiune={this.props.url_dimensiune}
        />
      );
    } else if (this.props.questionData.checkBox) {
      return (
        <CheckBox
          url_ans={this.props.url_ans}
          questionData={this.props.questionData}
          handleChange={(e) => {
            this.props.handleChange(e);
          }}
        />
      );
    } else
      return (
        <StandardInput
          url_ans={this.props.url_ans}
          questionData={this.props.questionData}
          handleChange={(e) => {
            this.props.handleChange(e);
          }}
        />
      );
  }
  async handleSkip() {
    await this.props.handleChangeInstant(null, "prepChanges");
    this.props.handleNext();
  }
  get getSkip() {
    if (this.props.questionData.nuStiu)
      return (
        <div className="skip-button" onClick={() => this.handleSkip()}>
          Nu știu
        </div>
      );
  }
  get getPage() {
    if (this.props.questionData === undefined) {
      return <span>ayayayay</span>;
    } else {
      let image = null;
      if (this.props.questionData.Image !== null) {
        image = (
          <img src={this.props.questionData.Image} style={{ width: "100%" }} />
        );
      }
      console.log(this.props.questionData.Question);
      return (
        <div className="page">
          {this.getVideo}
          <div className="image">{image}</div>
          <div className="title">{this.props.questionData.Question}</div>
          <div className="descriere">
            {this.props.questionData.QuestionDescriere}
          </div>
          {this.getAnswer}
          {this.getSkip}
        </div>
      );
    }
  }

  render() {
    return <div>{this.getPage}</div>;
  }
}

class TurismeControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionData: undefined,
      resultShow: false,
      durabilitate: 0,
      questIdList: [Number(this.props.first_pageID)],
    };
  }
  componentDidMount() {
    let str = this.props.url_quest + this.props.first_pageID;
    axios.get(str).then((res) => this.setState({ questionData: res.data[0] }));
  }

  get getPage() {
    if (this.state.resultShow) {
      return (
        <ResultControl
          filterUrlList={this.state.filterUrlList}
          marcaFilter={this.props.url_brand}
          profilFilter={this.props.url_profil}
          axa={this.state.axa}
          url_ans={this.props.url_ans}
          url_quest={this.props.url_quest}
          url_dimensiune={this.props.url_dimensiune}
          tipus={this.props.tipus}
          utilizare={this.state.Categorie}
          questIdList={this.state.questIdList}
          handleChange={(e) => {
            this.handleNoResultsChange(e);
          }}
          handleChangeInstant={(e, attr) => {
            this.handleNoResultInstant(e, attr);
          }}
        />
      );
    }
    if (this.state.questionData)
      return (
        <Page
          tipus={this.props.tipus}
          url_ans={this.props.url_ans}
          url_dimensiune={this.props.url_dimensiune}
          questionData={this.state.questionData}
          utilizare={this.state.Categorie}
          handleChange={(e) => {
            this.handleChange(e);
          }}
          handleChangeInstant={(e, attr) => {
            this.setState({ [attr]: e });
          }}
          handleNext={() => this.handleNext()}
          key={this.state.questionData.id}
        />
      );
  }

  async defFilterUrl() {
    let filterUrlList = [];
    if (this.props.tipus === "offroad") {
      let str = this.props.url_filter;
      str += "?Categorie=";
      str += this.state.Categorie;
      if (this.state["dimensiune"])
        if (this.state["dimensiune"].includes("/")) {
          let dim = this.state["dimensiune"].split("/");
          str += "&LatimeDenumire=";
          str += dim[0];
          dim = dim[1].split(" ");
          str += "&InaltimeDenumire=";
          str += dim[0];
          str += "&Raza=";
          str += dim[1];
        } else {
          let dim = this.state["dimensiune"].split("R");
          str += "&LatimeDenumire=";
          str += dim[0];
          str += "&InaltimeDenumire=";
          str += "&Raza=";
          str += dim[1];
        }
      str += "&grupaContains=";
      if (this.state.Segment) str += this.state.Segment;
      str += "&Utilizare=";
      if (this.state.Utilizare) str += this.state.Utilizare;
      let filterBlock = ["asd", str];
      filterUrlList.push(filterBlock);
    } else if (this.props.tipus === "camion") {
      if (this.state.axa !== undefined || this.state.axa !== undefined) {
        this.state.axa.map((axa) => {
          let str = this.props.url_filter;
          //http://127.0.0.1:8000/api/anvelope/anvelope/?Categorie=ANV.CAMION+17.5-24.5&LatimeDenumire=asd&InaltimeDenumire=dsa&Raza=sad&Marca__Segment=3&Utilizare=asd&SI=&Sezon=&grFilter=&liFilter=&agriflex=&grupaContains=&aderenta=&consum=
          str += "&Utilizare=";
          if (this.state.aplicatie) {
            str += this.state.aplicatie;
          }
          if (this.state["dimensiune"])
            if (this.state["dimensiune"].includes("/")) {
              let dim = this.state["dimensiune"].split("/");
              str += "&LatimeDenumire=";
              str += dim[0];
              dim = dim[1].split(" ");
              str += "&InaltimeDenumire=";
              str += dim[0];
              str += "&Raza=";
              str += dim[1];
            } else {
              console.log(this.state.dimensiune);

              let dim = this.state["dimensiune"].split("R");
              console.log(dim);

              str += "&LatimeDenumire=";
              str += dim[0];
              str += "&InaltimeDenumire=";
              str += "&Raza=";
              str += dim[1];
            }
          str += "&Marca__Segment=";
          if (this.state.segment) {
            str += this.state.segment;
          }
          str += "&axa=";
          if (axa) {
            str += axa;
          }
          let eti = "";
          if (axa === "T") {
            eti = "TRACȚIUNE";
          } else if (axa === "R") {
            eti = "REMORCĂ";
          } else {
            eti = "DIRECȚIE";
          }

          let t = [eti, str];
          filterUrlList.push(t);
        });
      } else {
        let str = this.props.url_filter;
        //http://127.0.0.1:8000/api/anvelope/anvelope/?Categorie=ANV.CAMION+17.5-24.5&LatimeDenumire=asd&InaltimeDenumire=dsa&Raza=sad&Marca__Segment=3&Utilizare=asd&SI=&Sezon=&grFilter=&liFilter=&agriflex=&grupaContains=&aderenta=&consum=
        str += "&Utilizare=";
        if (this.state.aplicatie) {
          str += this.state.aplicatie;
        }
        if (this.state["dimensiune"])
          if (this.state["dimensiune"].includes("/")) {
            let dim = this.state["dimensiune"].split("/");
            str += "&LatimeDenumire=";
            str += dim[0];
            dim = dim[1].split(" ");
            str += "&InaltimeDenumire=";
            str += dim[0];
            str += "&Raza=";
            str += dim[1];
          } else {
            console.log(this.state.dimensiune);

            let dim = this.state["dimensiune"].split("R");
            console.log(dim);

            str += "&LatimeDenumire=";
            str += dim[0];
            str += "&InaltimeDenumire=";
            str += "&Raza=";
            str += dim[1];
          }
        str += "&Marca__Segment=";
        if (this.state.segment) {
          str += this.state.segment;
        }
        str += "&axa=";
        if (this.state.axa) {
          str += this.state.axa;
        }
      }
    } ///Filter Url Camion
    else if (this.props.tipus === "jante") {
      let str = this.props.url_filter;
      //http://127.0.0.1:8000/api/jantecamion/jante/?Prezoane=&DiametruGauraButuc=&Produs=&DimeniuniAnvelopeCompatibile=265%2F70R17.5
      str += "?Produs=";
      if (this.state.JantaDim) {
        str += this.state.JantaDim;
      }
      if (this.state.dimensiune) {
        str += "&DimeniuniAnvelopeCompatibile=";
        let l = this.state.dimensiune.replace(/ /g, "");
        str += l;
      }
      if (this.state.nrPrez) {
        str += "&Prezoane=";
        str += this.state.nrPrez;
      }
      if (this.state.diamGaurCent) {
        str += "&DiametruGauraButuc=";
        str += this.state.diamGaurCent;
        str += " mm";
      }
      console.log(str);
      filterUrlList.push(str);
    } else {
      let str = this.props.url_filter;
      if (this.state.dimensiune)
        if (this.state.dimensiune.includes("/")) {
          let d = this.state.dimensiune.split("/");
          str += "&LatimeDenumire=";
          str += d[0];
          d = d[1].split("R");
          str += "&InaltimeDenumire=";
          str += d[0];
          str += "&Raza=";
          str += d[1];
        } else {
          let d = this.state.dimensiune.split("R");
          str += "&LatimeDenumire=";
          str += d[0];
          str += "&Raza=";
          str += d[1];
        }
      str += "&grupaContains=";
      if (this.state.vechicol) {
        str += this.state.vechicol;
      }
      if (this.state.sezon) {
        str += " ";
        str += this.state.sezon;
      }
      if (this.state.segment) {
        str += " ";
        str += this.state.segment;
      }
      if (this.state.aderenta) {
        str += "&aderenta=";
        str += this.state.aderenta;
      }
      if (this.state.si) {
        str += "&siFilter=";
        str += this.state.si;
      }
      let filterBlock = ["asd", str];
      filterUrlList.push(filterBlock);
    }
    this.setState({ filterUrlList: filterUrlList });
  }
  async handleNoResultsChange(e) {
    await e.map((it) => {
      if (it.ans) this.setState({ [it.question]: it.ans });
    });
    this.defFilterUrl();
  }
  async handleNoResultInstant(e, attr) {
    await this.setState({ [attr]: e });
    this.defFilterUrl();
  }
  async handleNext() {
    ///VALTOZASOK BEIKTATASA
    console.log(this.state.prepChanges);
    if (this.state.prepChanges) {
      await this.state.prepChanges.map((item) => {
        if (item.ans) {
          this.setState({ [item.question]: item.ans });
        } else if (item.numericans) {
          console.log(item.numericans);
          let n = this.state[item.question];
          console.log(n);
          n += Number(item.numericans);
          console.log(n);
          this.setState({ [item.question]: n });
        }
      });
    }

    this.setState({ prepChanges: null });
    ///KOVETKEZO OLDAL
    let ok = false;
    let i = 0;
    let nextPage = null;
    while (!ok) {
      let cond = this.state.questionData.NextQuestionCondition[i];
      let condBoolean = true;
      console.log(cond);
      cond.condition.map((c) => {
        if (c.question === "default") {
          ok = true;
          nextPage = cond.nextPageId;
        } else if (this.state[c.question] === c.answer) {
          ok = true;
          nextPage = cond.nextPageId;
        } else {
          condBoolean = false;
        }
      });
      if (!condBoolean) {
        ok = false;
      }
      if (nextPage === "-1") {
        await this.defFilterUrl();
        this.setState({ resultShow: true });
      }
      i++;
    }
    console.log(nextPage);
    let temp = this.state.questIdList;
    temp.push(nextPage);
    this.setState({ questIdList: temp });
    axios
      .get(this.props.url_quest + nextPage)
      .then((res) => this.setState({ questionData: res.data[0] }));
  }
  handleChange = (e) => {
    this.setState({ prepChanges: e });
  };
  async handlePrev() {
    if (this.state.questIdList.length > 1) {
      this.setState({ prepChanges: null });
      console.log(this.state.questIdList[this.state.questIdList.length - 2]);
      let nextPage = this.state.questIdList[this.state.questIdList.length - 2];
      axios
        .get(this.props.url_quest + nextPage)
        .then((res) => this.setState({ questionData: res.data[0] }));
      let temp = this.state.questIdList;
      temp.pop();
      this.setState({ questIdList: temp });
    }
  }
  get getButtons() {
    if (!this.state.resultShow) {
      return (
        <div className="button-bar">
          <div onClick={() => this.handlePrev()} className="prev-question">
            Întrebarea anterioară
          </div>
          <div onClick={() => this.handleNext()} className="next-question">
            Următoarea întrebare
          </div>
        </div>
      );
    }
  }
  render() {
    return (
      <div>
        {this.getPage}
        {this.getButtons}
      </div>
    );
  }
}
export default TurismeControl;
