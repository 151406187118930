import React, { Component } from "react";
import "./style/bottombar.css";

class BottomBar extends React.Component {
  prevOnClick = () => {
    console.log("ye");
    this.props.handlePrevClick();
  };
  nextOnClick = () => {
    console.log("ye");
    this.props.handleNextClick();
  };
  submitOnClick = () => {
    console.log("ye");
    this.props.handleSubmitClick();
  };
  get prevButton() {
    if (this.props.pageNum > 0) {
      return (
        <li
          className="bottom-button"
          onClick={() => {
            console.log("button clicked");
            this.prevOnClick();
          }}
        >
          <div>Previous</div>
        </li>
      );
    }
    return null;
  }
  get nextButton() {
    if (this.props.pageNum < this.props.noOfPages) {
      return (
        <li
          className="bottom-button"
          onClick={() => {
            console.log("button clicked");
            this.nextOnClick();
          }}
        >
          <div>Next</div>
        </li>
      );
    }
    return null;
  }
  get submitButton() {
    if (this.props.pageNum === this.props.noOfPages) {
      return (
        <li
          className="bottom-button"
          onClick={() => {
            console.log("button clicked");
            this.nextOnClick();
            this.submitOnClick();
          }}
        >
          <div>Afisează rezultate</div>
        </li>
      );
    }
    return null;
  }

  render() {
    return (
      <ul className="bottom-bar">
        {this.prevButton}
        {this.nextButton}
        {this.submitButton}
      </ul>
    );
  }
}

export default BottomBar;
