import React, { Component } from "react";
import "./style/extraFilter.css";

let data = [
  {
    show: "SG",
    descriere: "yadayadayada",
    property: "index",
    value: "SG",
  },
];
class ExtraFilterControl extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.setState({ data: data });
  }
  render() {
    return <div className="extra-filter-container">&lt;</div>;
  }
}
export default ExtraFilterControl;
