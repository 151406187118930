import React from "react";

function ExtraQuestion(props) {
  return (
    <div className="page" style={{ marginTop: "0.5rem" }}>
      <div className="title">{props.question.QuestionText}</div>
      <div className="agro-numeric-input" style={{ margin: "1rem" }}>
        <input
          type="numeric"
          onChange={(e) => {
            props.handleChange(e.target.value, props.question.QuestionVar);
          }}
        />
        <span>m</span>
      </div>
    </div>
  );
}

export default ExtraQuestion;
