import React, { Component } from "react";
import axios from "axios";

import BottomBar from "../other/bottombar";

import "./style/formular.css";

import jantaimg from "./images/janta.jpg";
import tractor from "./images/tractorback.jpg";
import prezon1 from "./images/prezon1.jpg";
import prezon2 from "./images/prezon2.jpg";
import prezon3 from "./images/prezon3.jpg";
let prezon = [prezon1, prezon2, prezon3];

function RadioButtonList(props) {
  return (
    <div className="section">
      <div className="section-title">{props.Title}</div>
      {props.listRaspuns.map((rasp, index) => {
        let img = null;
        if (props.radioName === "tipPrezon") {
          img = prezon[index];
          console.log(img);
        }
        console.log(props.startsAt + index);
        return (
          <div
            className="radiobutton-input"
            onChange={(e) => {
              props.onChange(e);
            }}
          >
            <div className="radio-image">
              <img src={img} />
            </div>
            <input
              type="radio"
              value={rasp}
              id={index}
              name={props.radioName}
            />
            <label for={index}>
              <div className="radio-button-circle">
                <div></div>
              </div>
              <span>{rasp}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
}

function TextInputList(props) {
  return (
    <div className="section">
      <div className="section-title">{props.Title}</div>
      {props.listIntrebare.map((res) => {
        return (
          <div className="question-box">
            <div className="question-label">{res.intrebare}</div>
            <div>
              <input
                type="text"
                name={res.name}
                onChange={(e) => {
                  props.onChange(e);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

function DoubleNumericInput(props) {
  return (
    <div className="section">
      <div className="section-title">{props.Title}</div>
      {props.listIntrebare.map((res, index) => {
        return (
          <div className="formular-dimensiune-box">
            <div className="formular-dimensiune-label">{res.intrebare}</div>
            <div className="fromular-dimensiune-input">
              <input
                type="number"
                key={index * 2}
                placeholder="Față"
                style={{ textAlign: "right" }}
                onFocus={() => {
                  props.handleFocus(index);
                }}
                name={res.name + "fata"}
                onChange={(e) => {
                  props.onChange(e);
                }}
              />
              /
              <input
                type="number"
                key={index * 2 + 1}
                placeholder="Spate"
                onFocus={() => props.handleFocus(index)}
                name={res.name + "spate"}
                onChange={(e) => {
                  props.onChange(e);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

function NumericInputListUnits(props) {
  return (
    <div className="section">
      {props.listIntrebare.map((res, index) => {
        console.log(props.startsAt + index);
        console.log(res.name);
        return (
          <div className="question-box">
            <div className="question-label">{res.intrebare}</div>
            <div className="formular-data-input">
              <input
                type="number"
                id={index}
                onFocus={() => {
                  props.handleFocus(index);
                }}
                name={res.name}
                style={{ border: "none" }}
                onChange={(e) => {
                  props.onChange(e);
                }}
              />
              <span>{res.unit}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

class Formular extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNum: 0,
      numOfpages: 2,
      focusCSS: "",
      FocusTractorCSS: "",
      DiametruGauraPrezon: "",
      EcrSolicitat: "",
      PutereUtilaj: "",
      anFabr: "",
      axa: "",
      dimAnvelopefata: "",
      dimAnvelopespate: "",
      distDintrAnvExtfata: "",
      distDintrAnvExtspate: "",
      distDintrAnvIntfata: "",
      distDintrAnvIntspate: "",
      distDintrFlansefata: "",
      distDintrFlansespate: "",
      distIntrePrezOpuse: "",
      ecartamentfata: "",
      ecartamentspate: "",
      fabricant: "",
      gauraCentrala: "",
      model: "",
      nrPrezoane: "",
      tel: "",
      tipCultura: "",
      tipPrezon: "",
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  onFocus = (num) => {
    let str = "";
    if (num === 2) {
      str = "shape1";
    } else if (num === 3) {
      str = "shape2";
    }
    this.setState({
      focusCSS: str,
    });
  };
  onFocusTractor = (num) => {
    let str = "";
    if (num >= 1 && num <= 4) {
      str = "tractor-dist" + num;
    }
    this.setState({
      FocusTractorCSS: str,
    });
  };
  get Page() {
    if (this.state.pageNum === 0) {
      return (
        <div>
          <RadioButtonList
            Title={"Unde doriți să echipați janta?"}
            listRaspuns={["Față", "Spate"]}
            radioName={"axa"}
            startsAt={0}
            onChange={(e) => this.handleChange(e)}
          />
          <TextInputList
            Title={"Titlu"}
            listIntrebare={[
              { intrebare: "Fabricant", name: "fabricant" },
              { intrebare: "Model", name: "model" },
              { intrebare: "Tip cultură", name: "tipCultura" },
              { intrebare: "An fabricație", name: "anFabr" },
              { intrebare: "Nr. Tel", name: "tel" },
            ]}
            startsAt={1}
            onChange={(e) => this.handleChange(e)}
          />
          <NumericInputListUnits
            listIntrebare={[
              {
                intrebare: "Puterea utilajului",
                unit: "cp",
                name: "PutereUtilaj",
              },
            ]}
            handleFocus={() => {}}
            startsAt={5}
            onChange={(e) => this.handleChange(e)}
          />
        </div>
      );
    } else if (this.state.pageNum === 1) {
      return (
        <div>
          <div className="tractor-back">
            <img src={tractor} />
            <span className={this.state.FocusTractorCSS}></span>
          </div>
          <DoubleNumericInput
            Title={"Dimensiuni"}
            listIntrebare={[
              { intrebare: "Dimensiuni anvelope", name: "dimAnvelope" },
              {
                intrebare: "Distanța dintre anvelope - intern",
                name: "distDintrAnvInt",
              },
              { intrebare: "Distanța dintre flanse", name: "distDintrFlanse" },
              { intrebare: "Ecartament", name: "ecartament" },
              {
                intrebare: "Distanța dintre anvelope - extern",
                name: "distDintrAnvExt",
              },
            ]}
            handleFocus={(num) => this.onFocusTractor(num)}
            startsAt={12}
            onChange={(e) => this.handleChange(e)}
          />
        </div>
      );
    } else if (this.state.pageNum === 2) {
      return (
        <div>
          <RadioButtonList
            Title={"Tip gaura prezon:"}
            listRaspuns={[
              "Normal",
              "Conic (pe o parte)",
              "Conic pe ambele părți",
            ]}
            radioName={"tipPrezon"}
            startsAt={22}
            onChange={(e) => this.handleChange(e)}
          />
          <div className="janta-img">
            <img src={jantaimg} />
            <span class={this.state.focusCSS}></span>
          </div>
          <NumericInputListUnits
            Title={"Titlu"}
            listIntrebare={[
              {
                intrebare: "Diametru Gaura Prezon",
                unit: "mm",
                name: "DiametruGauraPrezon",
              },
              { intrebare: "Număr prezoane", unit: "", name: "nrPrezoane" },
              {
                intrebare: "Distanța între prezoane diametral opuse",
                unit: "mm",
                name: "distIntrePrezOpuse",
              },
              {
                intrebare: "Diametru gaură centrală",
                unit: "mm",
                name: "gauraCentrala",
              },
              {
                intrebare: "Ecartament solicitat",
                unit: "mm",
                name: "EcrSolicitat",
              },
            ]}
            handleFocus={(num) => this.onFocus(num)}
            startsAt={23}
            onChange={(e) => this.handleChange(e)}
          />
        </div>
      );
    }
  }
  handlePrev = () => {
    this.setState((prevState) => {
      return { pageNum: prevState.pageNum - 1, resultsAreReady: false };
    });
  };

  handleNext = () => {
    this.setState((prevState) => {
      return { pageNum: this.state.pageNum + 1 };
    });
  };

  handleSubmit = () => {
    console.log(this.state);
    axios
      .post("http://127.0.0.1:8000/api/janteagro/formular/", {
        DiametruGauraPrezon: this.state.DiametruGauraPrezon,
        EcrSolicitat: this.state.EcrSolicitat,
        PutereUtilaj: this.state.PutereUtilaj,
        anFabr: this.state.anFabr,
        axa: this.state.axa,
        dimAnvelopefata: this.state.dimAnvelopefata,
        dimAnvelopespate: this.state.dimAnvelopespate,
        distDintrAnvExtfata: this.state.distDintrAnvExtfata,
        distDintrAnvExtspate: this.state.distDintrAnvExtspate,
        distDintrAnvIntfata: this.state.distDintrAnvIntfata,
        distDintrAnvIntspate: this.state.distDintrAnvIntspate,
        distDintrFlansefata: this.state.distDintrFlansefata,
        distDintrFlansespate: this.state.distDintrFlansespate,
        distIntrePrezOpuse: this.state.distIntrePrezOpuse,
        ecartamentfata: this.state.ecartamentfata,
        ecartamentspate: this.state.ecartamentspate,
        fabricant: this.state.fabricant,
        gauraCentrala: this.state.gauraCentrala,
        model: this.state.model,
        nrPrezoane: this.state.nrPrezoane,
        tel: this.state.tel,
        tipCultura: this.state.tipCultura,
        tipPrezon: this.state.tipPrezon,
      })
      .then()
      .catch((error) => {
        console.error(error);
      });
    this.setState({
      resultsAreReady: true,
    });
  };
  render() {
    return (
      <div style={{ paddingTop: "63px" }}>
        {this.Page}
        <BottomBar
          pageNum={this.state.pageNum}
          noOfPages={this.state.numOfpages}
          handlePrevClick={this.handlePrev}
          handleNextClick={this.handleNext}
          handleSubmitClick={this.handleSubmit}
        />
      </div>
    );
  }
}

export default Formular;
