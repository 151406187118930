import React from "react";
import { Link } from "react-router-dom";
import "./style/toolbar.css";
import DrawerToggleButton from "./drawertogglebutton";
import logo from "./images/logo.png";
const ToolBar = (props) => (
  <header className="toolbar">
    <nav className="toolbar__navigation">
      <div className="toolbar__toggle-button">
        <DrawerToggleButton click={props.click} />
      </div>
      <div className="toolbar__logo">
        <a href="https://nexxon.ro/">
          <img src={logo} />
        </a>
      </div>
      <div className="spacer" />
      <div className="toolbar__navigation-items">
        <ul className="toolbar__navigation-list">
          <li>
            <Link to="/turisme">Turisme</Link>
          </li>
          <li>
            <Link to="/agro">Agro</Link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
);

export default ToolBar;
