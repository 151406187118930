import React, { Component } from "react";
import axios from "axios";

class IndustrieList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      raspunsList: undefined,
    };
  }
  componentDidMount() {
    axios
      .get("http://127.0.0.1:8000/api/agro/utilizare/")
      .then((res) => this.setState({ raspunsList: res.data }));
  }
  handleChange = (e) => {
    this.props.handleChange(e.target.value);
  };
  get getRasp() {
    if (this.state.raspunsList !== undefined) {
      return (
        <div className="option-list">
          {this.state.raspunsList.map((item) => {
            return (
              <div
                className="radiobutton-input"
                onChange={(e) => {
                  this.handleChange(e);
                }}
                key={item.id}
              >
                <input
                  type="radio"
                  name="industrie"
                  id={item.id}
                  value={item.id}
                ></input>
                <label for={item.id}>
                  <span
                    style={{ display: "flex", flexDirection: "flex-start" }}
                  >
                    <div className="radio-button-circle">
                      <div></div>
                    </div>
                    <span>{item.UtilizareDenumire}</span>
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }
  render() {
    return <div>{this.getRasp}</div>;
  }
}
export default IndustrieList;
