import React, { Component } from "react";
import abroncs from "./images/anvelopa.jpg";
import cart from "./images/shoppingcart.png";
import exit from "./images/arrow.png";
import axios from "axios";
import "./style/item-detail.css";

import logo from "./images/logo.png";

function priceFormating(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " Lej";
}

function SugestiiCard(props) {
  function handleClick() {
    props.handleClick(props.Item);
  }
  let produsDenumire = "";

  produsDenumire = props.Item.Produs;

  return (
    <div className="sugestii-card" onClick={() => handleClick()}>
      <img src={abroncs} />

      <div className="sugestii-title">{produsDenumire}</div>
      <div className="sugestii-price">
        {priceFormating(props.Item.PretLista)}
      </div>
    </div>
  );
}

export default class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Marca: null,
      Profil: null,
      Latime: this.props.anvelopa.LatimeDenumire,
      Inaltime: this.props.anvelopa.InaltimeDenumire,
      Raza: this.props.anvelopa.Janta,
      Tub: this.props.anvelopa.TTTL,
      LoadIndex: this.props.anvelopa.LI,
      SpeedIndex: this.props.anvelopa.SI,
      Piliuri: this.props.anvelopa.PR,
      Sezon: this.props.anvelopa.Sezon,
    };

    let filterUrl1 = this.props.marcaFilter + this.props.anvelopa.Marca;
    let filterUrl2 =
      this.props.profilFilter + this.props.anvelopa.ProfilDenumire;
    axios.get(filterUrl1).then((res) => {
      this.setState({
        Marca: res.data[0]["Brand"],
      });
    });
    axios.get(filterUrl2).then((res) => {
      console.log(res.data);
      let str = "ProfilDenumire";
      if (res.data[0][str] === null) str = "ProfilDenumire";
      this.setState({
        ProfilDenumire: res.data[0][str],
      });
    });
  }
  handleExit = () => {
    this.props.onExit();
  };

  get getDetailList() {
    return (
      <div className="detail-list">
        {this.props.detailList.data.map((line, index) => {
          if (this.props.anvelopa)
            if (
              this.props.anvelopa[line.variable] !== undefined &&
              this.props.anvelopa[line.variable] !== null &&
              this.props.anvelopa[line.variable] !== ""
            ) {
              if (
                line.variable === "ProfilDenumire" ||
                line.variable === "Marca"
              ) {
                return (
                  <div key={index}>
                    <div>{line.show}</div>
                    <div>{this.state[line.variable]}</div>
                  </div>
                );
              } else
                return (
                  <div key={index}>
                    <div>{line.show}</div>
                    <div>{this.props.anvelopa[line.variable]}</div>
                  </div>
                );
            }
          return null;
        })}
      </div>
    );
  }

  render() {
    let produsDenumire = "";

    produsDenumire = this.props.anvelopa.Produs;

    return (
      <div className="detail-container">
        <span className="exit" onClick={() => this.handleExit()}>
          <img src={exit} />
        </span>
        <div className="detail-view">
          <div className="detail-image">
            <img src={abroncs} />
          </div>
          <div className="detail-content">
            <div className="detail-title">{produsDenumire}</div>
            <div className="detail-price">
              {priceFormating(this.props.anvelopa.PretLista)}
            </div>

            <div className="detail-descriere">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </div>
            <div className="specificatii">Specificații:</div>
            {this.getDetailList}
            <div className="buy">
              <div
                onClick={() => {
                  this.props.addProdus(this.props.anvelopa);
                }}
              >
                Cumpără acum!
              </div>
            </div>
            <div className="specificatii">Iți mai recomandăm și</div>
            <div className="alte-sugestii">
              {this.props.OtherSugestions.map((item, index) => {
                return (
                  <SugestiiCard
                    key={index}
                    Item={item}
                    handleClick={() => {
                      this.props.handleClick(item);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
