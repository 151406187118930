import React, { Component } from "react";
import axios from "axios";

class UtilajList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      raspunsList: undefined,
    };
  }
  componentDidMount() {
    axios
      .get(
        "http://127.0.0.1:8000/api/agro/utilaj/?Utilizare=" +
          this.props.utilizareID
      )
      .then((res) => this.setState({ raspunsList: res.data }));
  }
  get getList() {
    if (this.state.raspunsList)
      return (
        <div className="option-list">
          {this.state.raspunsList.map((item) => {
            return (
              <div
                className="radiobutton-input"
                onChange={(e) =>
                  this.props.handleChange(e.target.value, "utilajID")
                }
                key={item.id}
              >
                <input
                  type="radio"
                  name="utilaj"
                  id={item.id}
                  value={item.id}
                />
                <label for={item.id}>
                  <span
                    style={{ display: "flex", flexDirection: "flex-start" }}
                  >
                    <div className="radio-button-circle">
                      <div></div>
                    </div>
                    <span>{item.UtilajDenumire}</span>
                  </span>
                </label>
                <div className="radio-descriere">{item.descriere}</div>
              </div>
            );
          })}
        </div>
      );
    else return <div>az</div>;
  }
  render() {
    return <div>{this.getList}</div>;
  }
}

export default UtilajList;
